import classNames from 'classnames';
import { bool, func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useConfiguration } from '../../context/configurationContext';

import { isBookingProcessAlias } from '../../transactions/transaction';
import { displayPrice } from '../../util/configHelpers';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { propTypes } from '../../util/types';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { createSlug } from '../../util/urlHelpers';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import { useDispatch, useSelector } from 'react-redux';
import favouriteIconLiked from '../../assets/favouriteIconLiked.svg';
import favouriteIconNotLiked from '../../assets/favouriteIconNotLiked.svg';
import { updateFavourites } from '../../containers/SearchPage/SearchPage.duck';
import { createResourceLocatorString } from '../../util/routes';
import CustomOverlay from '../CustomOverLay/CustomOverlay';
import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl, haveStock } = props;
  const { listingType, offerFreeShipping } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);

  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {!haveStock ? <span>Sold • </span> : null}
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    showFavoriteIcons,
    history,
    location,
    routeConfiguration,
  } = props;
  const dispatch = useDispatch();
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { offerFreeShipping } = publicData || {};
  const hasFreeShipping = offerFreeShipping && offerFreeShipping.includes('freeShipping');

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  // For liking functionality
  const { SearchPage: searchPageReducer, user: userReducer } = useSelector(state => state);
  const { currentUser } = userReducer;
  const { updateFavouritesInProgress } = searchPageReducer || {};

  const [liked, setLiked] = useState(false);
  const heartIconClass = liked ? `${css.heartIcon} ${css.liked}` : css.heartIcon;
  const isOwnListing = author?.id?.uuid === currentUser?.id?.uuid;
  const showHeartIcon = showFavoriteIcons && !isOwnListing;
  const userFavouriteListings =
    (currentUser && currentUser.attributes.profile.publicData.favoriteListings) || [];
  const { currentStock } = listing;
  const { attributes } = currentStock || {};

  const haveStock = attributes?.quantity > 0 || false;

  useEffect(() => {
    if (isLiked()) {
      setLiked(true);
    }
  }, [userFavouriteListings]);

  const isLiked = () => {
    const likedListings = userFavouriteListings;
    return likedListings && likedListings.includes(currentListing.id.uuid);
  };

  const toggleFavourite = event => {
    event.preventDefault();

    // If user is not logged in redirect to sign in before they can proceed with favouriting
    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };
      history.push(createResourceLocatorString('SignupPage', routeConfiguration, {}, {}), state);
    } else {
      if (updateFavouritesInProgress) return;
      setLiked(!liked);

      const likedListings = userFavouriteListings;
      let newListings = likedListings ? likedListings : [];
      if (isLiked()) {
        newListings = likedListings.filter(l => l !== currentListing.id.uuid);
      } else {
        newListings.push(currentListing.id.uuid);
      }
      dispatch(updateFavourites(newListings));
    }
  };

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      {hasFreeShipping && (
        <div
          className={css.deliveryIconWrapper}
          onClick={e => {
            e.preventDefault();
          }}
        >
          <div className={css.deliveryIcon}></div>
        </div>
      )}
      {showHeartIcon && (
        <div className={css.heartImageWrapper}>
          <div className={css.listingLike}>
            <div
              className={
                updateFavouritesInProgress ? classNames(css.disabled, css.heartIcon) : css.heartIcon
              }
              onClick={toggleFavourite}
            >
              <img
                src={liked ? favouriteIconLiked : favouriteIconNotLiked}
                className={css.favouriteIcon}
              />
            </div>
          </div>
        </div>
      )}
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
        {!haveStock ? <CustomOverlay /> : null}
      </AspectRatioWrapper>
      <div className={css.info}>
        <PriceMaybe
          price={price}
          publicData={publicData}
          config={config}
          intl={intl}
          haveStock={haveStock}
        />

        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
